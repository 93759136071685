import React from "react"
import { Flex, NavLink, Styled } from "theme-ui"
import { Link } from "gatsby"
import RequestPasswordRecovery from "@forms/RequestPasswordRecovery"
import Layout from "@layouts/form"

export default () => (
  <Layout title="Odzyskiwanie hasła">
    <Styled.h4
      style={{
        color: `#000`,
        fontSize: `22px`,
        lineHeight: `25px`,
        textAlign: `center`,
        flex: '1 0 100%'
      }}
    >
      Zapomniałeś hasła?
    </Styled.h4>
    <Styled.p
      style={{
        letterSpacing: `0px`, 
        color: `#7D7D7D`,
        fontSize: `13px`,
        lineHeight: `25px`,
        fontFamily: `Poppins`,
        fontWeight: 400,
        textAlign: `center`,
        paddingTop: "2rem",
        paddingBottom: "2rem",
      }}
    >
      Wpisz swój email i odzyskaj dostęp do swojego konta
    </Styled.p>
    <RequestPasswordRecovery />
    <Flex>
      <NavLink
        as={Link}
        to="/login"
        sx={{
          color: `#000`,
          textAlign: "right",
          fontWeight: 400,
          fontFamily: 'Poppins',
          fontSize: `13px`,
          letterSpacing: "0px",
          pt: "1rem",
        }}
      >
        Oh, przypomniałem sobie! Wróć do logowania ;)
      </NavLink>
    </Flex>
  </Layout>
)
