import React from "react"
import { Formik } from "formik"
import { useIdentityContext } from "react-netlify-identity"
import { navigate } from "gatsby"
import { object, string } from "yup"
import { errorMessages } from "@const"
import RequestPasswordRecovery from "./RequestPasswordRecovery"

export default () => {
  const { requestPasswordRecovery } = useIdentityContext()
  const formikProps = {
    initialValues: {
      email: "",
    },
    onSubmit: ({ email }) =>
      requestPasswordRecovery(email, {})
        .then(() => {
          navigate("/")
        })
        .catch(error => {
          console.log(error)
        }),
    validationSchema: object().shape({
      email: string().email(errorMessages.emailNotValid).required(errorMessages.required),
    }),
  }
  return (
    <Formik
      {...formikProps}
      children={props => <RequestPasswordRecovery {...props} />}
    />
  )
}
