import React from "react"
import { Alert, Input, Label } from "theme-ui"
import { ErrorMessage, useField } from "formik";

export default ({children, placeholder, ...props}) => {
  const [field, meta, helpers] = useField(props);
  const { name } = field;
  const { error } = meta;

  return (
    <Label>
      <Input
        type="email"
        placeholder={placeholder}
        sx={{
          borderColor: error && `danger`
        }}
        {...field}
        {...meta}
        {...helpers}
        {...props}
      />
      <ErrorMessage
        name={name}
        component={({ children }) => (
          <Alert variant="fieldError">{children}</Alert>
        )}
      />
      {children}
    </Label>
  )
}