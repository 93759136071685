import React from "react"
import { Form } from "formik"
import { Button, Flex } from "theme-ui"

import EmailInput from "@fields/EmailInput"

export default ({ dirty, isSubmitting, isValid, values, ...props }) => (
  <Flex as={Form} sx={{ flexWrap: `wrap` }}>
    <EmailInput name="email" placeholder="Email" />
    <Button type="submit" variant="submit">Resetuj hasło</Button>
  </Flex>
)
